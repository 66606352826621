.navhead {
  display: flex;
  align-items: center;
  padding: 16px;
  button {
    background: none;
    border: none;
  }
  h1 {
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2;
    padding-left: 15px;
  }
}

.favourite {
  position: absolute;
  right: 16px;
  top: 16px;
}
