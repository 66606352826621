.gobtn {
  display: block;
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  padding: 11px;
  background: #f22486;
  border-radius: 100px;
}
.gobtnarrow {
  width: 100%;
  color: #327cd1;
  padding: 13px 19px 13px 12px;
  background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
  border-radius: 12px;
  text-decoration: none;
  font-weight: 600;
  position: relative;padding-right: 35px;
}

.gobtnarrow svg {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
}
.savebtn {
	font-size: 14px;
	color: #858585;
	text-align: center;
	border-radius: 10px;
	margin-top: 8px;
	padding: 4px;
	p {
		margin-top: 6px;
	}
	&:active {
		box-shadow: inset 0px 6px 16px rgba(0, 51, 102, 0.15);
	}
}
h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222;
  margin: 0;
}



.thumb {
  height: 150px;
  width: 100%;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
h2,
ul,
p {
  margin-top: 16px;
}
h2 {
  font-size: 16px;
}

.head-tl {
  h3 {
    font-size: 16px;
  }
  .link {
    color: #858585;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
  }
}
.text-center {
  text-align: center;
}

.player {
  .thumb {
    width: 200px;
    height: 192px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #222222;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.note {
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  span {
    color: #327cd1;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}


.load-more-button {
  margin: 0.5rem auto 0 auto;
}

.load-more-button button {
  padding: 0.3rem;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: #f22486;
  }
}
