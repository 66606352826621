.board-form {
  &-item {
    background: #ffffff;
    border-radius: 12px;
	 font-size: 14px;
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
	 .title-0 {
		margin-top: 0;
		font-weight: 600;
      font-size: 14px;
      line-height: 18px;
		color: #327CD1;
		cursor: pointer;
		text-decoration: underline;
	 }
	 .title {
		margin-top: 0;
		font-weight: 600;
      font-size: 14px;
      line-height: 18px;
		color: #327CD1;
	 }
	 .list-0{ 
		list-style-type: none;
		margin: 8px 0 4px;
	 }
	 .list-1{ 
		list-style-type: decimal;
		margin: 8px 0 4px;
		cursor: pointer;
		text-decoration: underline;
	 }
	 .list-2{ 
		list-style-type: disc;
		margin: 8px 0 4px;
		cursor: pointer;
		text-decoration: underline;
	 }
	 .list-3{ 
		list-style-type: disc;
		margin: 8px 0 4px;
	 }
    .inputs {
		display: flex;
		flex-direction: column;
		align-items: stretch;
      label {
        background: #ffffff;
        box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
        border-radius: 100px;
        padding: 11px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #327cd1;
        width: 100%;
        font-weight: 600;
        text-align: center;
		  cursor: pointer;
      }
      input {
        display: none;
        &:checked {
          + label {
            background: #327cd1;
            color: #ffffff;
          }
        }
      }
		
		
    }
  }
  button {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
    border-radius: 100px;
    padding: 11px 16px;
    font-weight: 600;
    &.clear {
      color: #327cd1;
      &.disabled {
        pointer-events: none;
        color: #c0bebf;
      }
    }
    &.next {
      color: #fff;
      background: #327cd1;
      &.disabled {
        pointer-events: none;
        background: #c0bebf;
      }
    }
  }
}
.ant-popover {
	width: 420px !important;
 }