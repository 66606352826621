.accordion {
    outline: none;
  .head {
    background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
    border-radius: 12px;
    padding: 12px 16px 12px 12px;
    cursor: pointer;
    .icon.is-open svg {
        transform: rotate(180deg);
    }
    .title {
        color: #327CD1;
    }
  }
  .content {
    background: #ffffff;
    box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
    border-radius: 0px 0px 12px 12px;
    padding: 16px 12px;
  }
  &.is-open {
    .head {
      border-radius: 12px 12px 0 0;
    }
  }
  ul {
    list-style-type: disc;
    padding-left: 24px;
  }
}
