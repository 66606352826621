
/*Topical*/
.clinic {
  overflow: hidden;
  position: relative;

  &-post {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &-item {
		cursor: pointer;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
      border-radius: 12px;
      width: 90%;
		margin-right: 15px;
      &:nth-last-child(1) {
        margin-right: 1em;
      }

      .img {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 12px 12px 0 0;
			margin-right: 15px;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .meta {
			text-align: center;
        padding: 8px 8px 0 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #327CD1;
      }

      .title {
			text-align: center;
        padding: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #327CD1;
      }
    }
  }
}

.block_01 {
  gap: 0.725rem;
}

.title_0 {
  font-size: 15px;
  color: #327CD1;
}

div.block_01{
  padding: 16px 16px;
  gap: 0.725rem;
}

.home_block_01 {
  margin-bottom: 1.35rem;
}

.answer {
	cursor: pointer;
	border-radius: 12px;
	max-width: 380px;
	width: 100%;
	background-color: #fff;
	padding: 12px;
	box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
}
.continue {
	background: #327cd1;
      box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
      border-radius: 100px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 11px 16px;
      width: 100%;
		height: 40px;
}
.end {
	width: 100%;
	color: #327cd1;
	padding: 12px;
	background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
	border-radius: 100px;
	text-decoration: none;
	font-weight: 600;
	position: relative;padding-right: 35px;
}

.test {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ant-modal {
	width: 380px !important;
	.ant-modal-title {
		text-align: center !important;
	}
}
.like {
	width: 55px;
}