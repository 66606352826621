.posts-wrapper-1{ 
	min-height: 85%;
	display: flex;
	flex-direction: column;
}
.posts-wrapper-2 { 
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
.posts-main {
	flex: 1 1 auto;
	padding: 0 16px;
}
.posts {
	
  a {
    text-decoration: none;
    color: #222;
  }
  &-item {
    border-bottom: 1px solid rgba(133, 133, 133, 0.24);
    display: grid;
    grid-template-columns: 1fr 110px;
    padding: 1rem 0;
    .meta {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #858585;
      margin-bottom: 8px;
    }
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .img {
      width: 110px;
      height: 72px;
      position: relative;
      flex-basis: 110px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &-scroll {
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &-item {
		cursor: pointer;
      max-width: 120px;
      width: 100%;
		text-align: center;
      .img {
        border-radius: 6px;
        margin-bottom: 8px;
        width: 120px;
        height: 120px;
        position: relative;
        flex-basis: 120px;
        overflow: hidden;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &-more {
        min-width: 120px;
        .link {
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          color: #222;
          text-decoration: none;
          .icon {
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: #222222;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
