.calc {
  &-item {
	 &-title {
		display: flex;
		justify-content: space-between;
		h3 {
			width: 50%;
		}
	 }
    background: #ffffff;
    box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
    border-radius: 12px;
    input {
      padding: 12px;
      background: #f5f7fb;
      border: 1px solid #327cd1;
      border-radius: 12px;
      color: #222222;
      width: 100%;
    }
  }
  
  button {
    &.add {
      background: #f5f7fb;
      border-radius: 12px;
      padding: 6px 10px 6px 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #327cd1;
    }
    &.clear {
      background: #ffffff;
      box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
      border-radius: 100px;
      color: #327cd1;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 11px 16px;
      width: 100%;
    }
    &.send {
      background: #327cd1;
      box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
      border-radius: 100px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 11px 16px;
      width: 100%;
    }
	 &.hide {
      background: #f5f7fb;
      border-radius: 12px;
      padding: 6px 10px 6px 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #327cd1;
    }
	 &.save {
      padding: 6px 10px 6px 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #858585;
	 }
  }
  .graph {
	background: #ffffff;
	border-radius: 12px;
	box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
	padding: 12px;
	height: fit-content;
  }
}
