.noaccess {
  height: 100%;
  width: 100%;
  padding: 0 20px;

  .text {
    font-family: Verdana Pro, sans-serif;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    text-align: center;
  }

  .span {
    color: #327cd1;
  }
}
