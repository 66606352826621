@import './styles/Reset.scss';

@font-face {
  font-family: 'Verdana Pro';
  src: url('./fonts/VerdanaPro-Regular.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Verdana Pro';
  src: local('Verdana Pro'), url('/fonts/VerdanaPro-SemiBold.ttf') format('ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Verdana Pro';
  src: local('Verdana Pro'), url('/fonts/VerdanaPro-Bold.ttf') format('ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Verdana Pro';
  src: local('Verdana Pro'), url('/fonts/VerdanaPro-Light.ttf') format('ttf');
  font-weight: 300;
}

html,
body {
  height: 100%;
  background-color: #f5f7fb;
  font-family: 'Verdana Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.35;
  color: #222;
}

.App {
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
  color: #222;
  width: 100%;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;

  &-column {
    flex-direction: column;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.justify {
  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }

  &-stretch {
    align-items: stretch;
  }
}

/*Generation Nums*/
$nums: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

/*gaps*/
@mixin gap($gap-val) {
  .gap-#{$gap-val} {
    gap: #{$gap-val * 0.5}em;
  }
}

@each $gap in $nums {
  @include gap($gap);
}

/*padding*/
@mixin padt($pt-val) {
  .pt-#{$pt-val} {
    padding-top: #{$pt-val * 0.5}em;
  }
}

@each $pt in $nums {
  @include padt($pt);
}

// right
@mixin padr($pr-val) {
  .pr-#{$pr-val} {
    padding-right: #{$pr-val * 0.5}em;
  }
}

@each $pr in $nums {
  @include padr($pr);
}

//bottom
@mixin padb($pb-val) {
  .pb-#{$pb-val} {
    padding-bottom: #{$pb-val * 0.5}em;
  }
}

@each $pb in $nums {
  @include padb($pb);
}

//left
@mixin padl($pl-val) {
  .pl-#{$pl-val} {
    padding-left: #{$pl-val * 0.5}em;
  }
}

@each $pl in $nums {
  @include padl($pl);
}

/*margin*/
@mixin mart($mt-val) {
  .mt-#{$mt-val} {
    margin-top: #{$mt-val * 0.5}em;
  }
}

@each $mt in $nums {
  @include mart($mt);
}

// right
@mixin marr($mr-val) {
  .mr-#{$mr-val} {
    padding-right: #{$mr-val * 0.5}em;
  }
}

@each $mr in $nums {
  @include marr($mr);
}

//bottom
@mixin marb($mb-val) {
  .mb-#{$mb-val} {
    margin-bottom: #{$mb-val * 0.5}em;
  }
}

@each $mb in $nums {
  @include marb($mb);
}

//left
@mixin marl($ml-val) {
  .ml-#{$ml-val} {
    margin-left: #{$ml-val * 0.5}em;
  }
}

@each $ml in $nums {
  @include marl($ml);
}

//full padding
@mixin padd($pd-val) {
  .pd-#{$pd-val} {
    padding: #{$pd-val * 0.5}em;
  }
}

@each $pd in $nums {
  @include padd($pd);
}

.content_x__block {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content_x {
  flex: 1 1 auto;
  padding: 0 16px;
}

.back_btn_x {
  padding-bottom: 0.75rem;
  margin-top: 5rem;
}

.loader {
  position: relative;
  width: 75px;
  top: calc(50vh - 37px);
  left: calc(50% - 37px);
}

.favourite-btn {
  position: absolute;
  right: 16px;
  top: 20px
}

.pixel {
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}

@import './styles/Home.scss';
@import './styles/Profile.scss';
@import './styles/Clinicc.scss';
@import './styles/Navhead.scss';
@import './styles/Posts.scss';
@import './styles/Accordion.scss';
@import './styles/Media.scss';
@import './styles/Board.scss';
@import './styles/Calc.scss';
@import './styles/More.scss';
@import './styles/Controlss.scss';
@import './styles/NoAccess.scss';
@import './styles/ForDoctor.scss';
