.profile-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.profile-img {
  background-color: #ffffff;
  box-shadow: 0px 6px 16px 0px #00336614;
  width: 70px;
  height: 70px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70px;
    height: 70px;
  }
}

.profile-info {
  padding: 16px 18px;

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
    padding: 0;
  }

  width: 100%;
  height: 226px;

  background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
}

.profile-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  p {
    font-size: 13px;
  }
}

.profile-edit {
  display: flex;
  gap: 4px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  color: #858585;
}

.profile-content {
  padding: 16px 18px;

  position: absolute;
  top: 176px;

  min-height: 40%;
  width: 100%;

  border-radius: 12px 12px 0px 0px;
  background-color: #f5f7fb;

  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    margin: 0 0 16px 0;
    padding: 0;
  }
}

.profile-progress {
  display: flex;
  flex-direction: column;
}

.achievements {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .achievement-item {
    display: flex;
    background-color: #ffffff;

    min-height: 58px;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;

    p {
      margin: 0;
    }

    .achievement-text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        font-size: 12px;
        line-height: 16px;
        color: #22222299;
      }
    }

    img {
      width: 55px;
    }
  }
}

.favourite-container {
  position: relative;
  display: flex;
  overflow-y: auto;
  position: relative;
  gap: 14px;

  &::-webkit-scrollbar {
    display: none;
  }

  .favourite-item {
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none!important;
    gap: 8px;
  }

  .favourite-item-content {
    width: 130px;
    height: 212px;
    background-color: #ffffff;
    box-shadow: 0px 6px 16px 0px #00336614;
    border-radius: 12px;

    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 130px;
      object-fit: cover;
      border-radius: 12px 12px 0 0;
    }

    a, p, h4 {
      text-decoration: none;
    }

    .favourite-item-text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: #858585;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        text-decoration: none;
      }
      
      h4 {
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        color: #222222;

        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}

.favourite-item-delete {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;

  font-size: 14px;
  font-weight: 600;
  color: #327cd1;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  right: 5px;
  top: 5px;
}


.profile-edit-container {
  background-color: #f5f7fb;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;

  width: 100%;
  min-height: 100vh;

  p {
    margin: 0;
  }
}

.edit-header {
  width: 100%;
  height: fit-content;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 600;
  }

}

.back-button {
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button-left {
  left: 16px;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .edit-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
}

.edit-input {
  background-color: #ffffff;
  height: 42px;
  border-radius: 8px;
  padding: 9px;
  display: flex;
  align-items: center;
  position: relative;

  justify-content: space-between;

  input {
    width: 100%;

    &:disabled {
      background-color: #ffffff;
    }
  }

  button {
    width: 32px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .a-bottom {
    rotate: -90deg;
  }

  .a-top {
    rotate: 90deg;
  }

  .edit-options {
    position: absolute;
    flex-direction: column;
    top: 43px;
    left: 0;
    width: 100%;
    display: none;

    .edit-options-item {
      width: 100%;
      height: 42px;
      background-color: #ffffff;

      border-top: 1px solid #979797;

      padding: 9px;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: flex-start;
    }
  }

  .edit-options-active {
    display: flex;
  }
}

.edit-avatars {
  display: flex;
  gap: 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .edit-avatars-item {
    min-width: 70px;
    height: 70px;
    border-radius: 12px;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70px;
      height: 70px;
    }
  }
  .avatar-active {
    background: linear-gradient(180deg, #d4f2fc 0%, #d6e0f4 100%);
  }
}

.edit-cookies {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: auto;

  gap: 16px;

  padding: 16px;

  a {
    font-size: 14px;
    font-weight: 400;
    color: #327cd1;
    text-decoration: none;
  }
}
