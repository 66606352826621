/*search*/
.search-form {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
  border-radius: 12px;
  display: block;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 4px 10px;

    &::placeholder {
      color: #858585;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0;
    margin-right: 5px;
  }
}

.search-list {
  cursor: pointer;
  &.active {
    font-weight: 600;
  }
}
/* Bg link*/
.bg-link {
  background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
  border-radius: 12px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.45;
  color: #327cd1;
  text-decoration: none;
  flex-basis: 50%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  padding-left: 10px;
}

.small-link {
  background: linear-gradient(180deg, #d4f3fc 0%, #d7d5f0 100%);
  border-radius: 12px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.45;
  color: #327cd1;
  text-decoration: none;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.avatar-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 12px;

  box-shadow: 0px 8px 20px 0px #327cd129;

  img {
    width: 32px;
    height: 32px;
  }
}

.btn-link {
  display: block;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 51, 102, 0.08);
  border-radius: 12px;
  flex-basis: calc(50% - 0.58em);
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #222;
  text-decoration: none;

  > div {
    padding: 8px 16px;
  }

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-video {
    .icon {
      background: #f22486;
    }
  }

  &-post {
    .icon {
      background: #327cd1;
    }
  }

  &-erleada {
    .icon {
      background: #3b0da4;
    }
  }

  &-clinic {
    .icon {
      background: #29d7f9;
    }
  }
}

div.block_01 {
}

/*Topical*/
.topical {
  overflow: hidden;
  position: relative;

  &-head {
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #222;
      margin: 0;
    }

    .link {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-decoration: none;
      color: #858585;
    }
  }

  &-post {
    /*display: grid;
   grid-template-columns: repeat(4, 1fr);*/
    display: flex;
   gap: 16px;
   align-items: stretch;
   width: calc(100% - 1em);
   margin-right: 1em;
   overflow-x: auto;
   -ms-overflow-style: none;
   scrollbar-width: none;
   cursor: pointer;
   &::-webkit-scrollbar {
     width: 0;
     height: 0;
   }

   &-item {
     position: relative;
     background: #ffffff;
     box-shadow: 0 6px 16px rgba(0, 51, 102, 0.08);
     border-radius: 12px;
     max-width: 140px;
     width: 100%;

/*      &:nth-last-child(1) {
       margin-right: 1em;
     }*/

      .img {
        position: relative;
        width: 140px;
        height: 116px;
        overflow: hidden;
        border-radius: 12px 12px 0 0;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .meta {
        padding: 8px 8px 0 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #858585;
      }

      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        padding: 8px;
        max-width: 100%;
        max-height: 65px;
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #222222;
      }
    }
  }
}

.block_01 {
  gap: 0.725rem;
}

.title_0 {
  font-size: 15px;
}

div.block_01 {
  padding: 16px 16px;
  gap: 0.725rem;
}

.home_block_01 {
  margin-bottom: 1.35rem;
}
