.poster {
	width: 100%;
}
.player {
	width: 100%;
}
.video {
	width: 100%;
}
.controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 330px;
	margin: 0 auto;
	height: 40px;
	img {
		height: 16px;
		cursor: pointer;
	}
	button {
		background: linear-gradient(180deg, #D4F3FC 0%, #D7D5F0 100%);
		border-radius: 12px;
		height: 40px;
		padding: 12px;
		color: #3820AB;
		display: flex;
		align-items: center;
		img {
			margin-right: 10px;
			&.pause {
				height: 28px;
			}
		}
		
	}
}
.times {
	height: fit-content;
	display: flex;
	justify-content: space-between;
	p {
		margin: 0;
	}
}
.progressBar {
  --bar-bg: rgba(0, 0, 0, 0.04);
  --seek-before-width: 0;
  --seek-before-color: #3820AB;
  --knobby: #3820AB;
  --selectedKnobby: #3820AB;

  appearance: none;
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 4px;
  outline: none;
}

/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 4px;
  outline: none;
}

/* progress bar - firefox */
.progressBar::-moz-range-track {
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 4;
  outline: none;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.progressBar::before {
  content: '';
  height: 4px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progress bar - firefox */
.progressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 11px;
}

/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -4px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

/* knobby - firefox */
.progressBar::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}